<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :items-per-page="10" sort-desc sort-by="ciccId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Cuentas de cobro</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="limpiar();dialog = true">
                        agregar
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>
                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                FECHA <span class="green--text"> {{item.ciccFecha}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                TIPO <span class="primary--text" v-if="item.tipopaquete">
                                                    {{item.tipopaquete.comaNombrelargo}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5" v-if="item.estado">
                                                ESTADO <span class="primary--text">{{ item.estado.comaNombrecorto}}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" v-on="on" small :to="{ name: 'Cuentascobrospanel',params: { id: item.ciccId} }">
                                                    <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ver facturas</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :loading="loadPdf" outlined color="info" class="mr-2" v-on="on" small @click="pdf(item.ciccId)">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar pdf de cuenta de cobro</span>
                                    </v-tooltip> -->
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn :loading="loadPdfExcel" outlined color="green" class="mr-2" v-on="on" small @click="excel(item.ciccId)">
                                                    <v-icon>mdi-file-excel</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar excel de cuenta de cobro</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :loading="loadPdfFactura" outlined color="info" class="mr-2" v-on="on" small @click="pdfFacturas(item.ciccId)">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar pdf de facturas</span>
                                    </v-tooltip> -->
                                        <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :loading="loadPdfHistoria" outlined color="info" class="mr-2" v-on="on" small @click="pdfHistorias(item.ciccId)">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar pdf de historias</span>
                                    </v-tooltip> -->
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="success" class="mr-2" v-on="on" @click="pagar(item.ciccId)">
                                                    <v-icon>mdi-currency-usd</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Pagar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn small outlined color="error" class="mr-2" v-on="on" @click="eliminar(item.ciccId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        CONTRATO <span class="primary--text">
                                            {{ item.tblConfContrato.cocoNombre}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        FECHA INICIAL <span class="primary--text">
                                            {{ item.ciccFechainicial}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        FECHA FINAL <span class="primary--text">
                                            {{ item.ciccFechafinal}}
                                        </span>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="5" md="5">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.form.ciccFecha.$error" v-model="form.ciccFecha" label="Fecha" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.ciccFecha" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="7" md="7">
                                    <v-text-field outlined required label="Numero de la cuenta" v-model="form.ciccNumFactura"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete outlined :error="$v.form.cocoId.$error" v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-autocomplete outlined :error="$v.form.ciccTipoPaquete.$error" v-model="form.ciccTipoPaquete" :items="tipopaquete" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de cuenta" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.form.ciccFechainicial.$error" v-model="form.ciccFechainicial" label="Fecha inicial" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.ciccFechainicial" @input="menu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.form.ciccFechafinal.$error" v-model="form.ciccFechafinal" label="Fecha final" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.ciccFechafinal" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete outlined :error="$v.form.ciccTipoEstadocuenta.$error" v-model="form.ciccTipoEstadocuenta" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado cuenta" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                <v-switch v-model="form.anteriores" label="Incluir atenciones anteriores a la fecha"></v-switch>
                              </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar" :loading="loading" >Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  documentos
}
from "../../utils/documentos";
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  components: {

  },
  data() {
    return {
      dialog: false,
      isNew: true,
      url: "cita/cuentascobros/",
      token: this.$cookies.get("token"),
      loading:false,
      nameRules: [v => !!v || "Campo requirido"],
      menu: null,
      menu1: null,
      menu2: null,
      form: {
        ciccId: null,

        ciccFecha: new Date().yyyymmdd(),
        ciccFechainicial: new Date().yyyymmdd(),
        ciccFechafinal: new Date().yyyymmdd(),
        ciccNumFactura: 0,
        ciccTipoPaquete: 0,
        cocoId: null,
        ciccTipoEstadocuenta: null,
        anteriores:false
      },
      filtros: [{
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Contrato",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "cocoNombre",
          listavalue: "cocoId",
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo,
              subtitle: data.item.cocoNombre
            }
          },

        },
        {
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        },
        {
          text: "Tipo de paquete",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        },
      ],

      lista: [],
      listaCopia: [],
      contratos: [],
      tipomes: [],
      tipoanio: [],
      tipoestado: [],
      tipopaquete: [],
      datosdian: [],
      empresas: [],
      loadPdf: false,
      loadPdfFactura: false,
      loadPdfHistoria: false,
      loadPdfExcel: false
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
    this.empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
    this.filtros[1].lista = this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
    this.filtros[2].lista = this.tipopaquete = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAQUETECUENTA").then(data => data);
    this.filtros[3].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CUENTAS_COBRO").then(data => data);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  validations: {
    form: {

      ciccFecha: {
        required,
      },
      ciccFechainicial: {
        required,
      },
      ciccFechafinal: {
        required,
      },
      ciccTipoPaquete: {
        required,
      },
      cocoId: {
        required,
      },
      ciccTipoEstadocuenta: {
        required,
      },
      ciccNumFactura: {
        required,
      },
    },
  },

  methods: {
    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        this.lista = this.listaCopia.filter(
          e => e.ciccFecha == this.filtros[0].value
        );
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        this.lista = this.listaCopia.filter(
          e => e.cocoId == this.filtros[1].value
        );
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        this.lista = this.listaCopia.filter(
          e => e.ciccTipoPaquete == this.filtros[2].value
        );
      }
      if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
        this.lista = this.listaCopia.filter(
          e => e.ciccTipoEstadocuenta == this.filtros[3].value
        );
      }

    },

    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        let data = null;
        this.loading=true;
        try {
          if (this.isNew) {
            data = await this.$apiService.create(this.url + "create?anteriores="+this.form.anteriores, this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.close();
            }

          } else {
            var id = this.form.ciccId;
            data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.lista = this.lista.filter(e => e.ciccId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.close();
            }

          }

        } catch (error) {
          console.log(error)
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.loading=false;
      }
    },
    async pagar(id) {
      try {
        let data = await this.$apiService.update(this.url + "pagar/" + id + "/", null).then(data => data);
        if (data) {
          this.lista = this.lista.filter(e => e.ciccId != id);
          this.listaCopia = this.lista.push(data);
          this.$swal.fire({
            title: "Completado!",
            text: "Cuenta pagada",
            icon: "success",
            confirmButtonText: "Ok"
          });

        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.ciccId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }

      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async pdf(id) {
      try {
        this.loadPdf = true;
        let repo = null;
        let tipo = null;
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_CON' || this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_SUB') {
          // var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=EVENTO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=EVENTO`).then(data => data);

          tipo = 'EVENTO';
        } else {
          //var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=CAPITADO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=CAPITADO`).then(data => data);

          tipo = 'CAPITADO';
        }

        if (repo.length > 0) {
          repo.sort((a, b) => a.cifaNumFactura.localeCompare(b.cifaNumFactura));
          //this.salario = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALARIOMINIMO/" + anio.comaId).then(data => data);
          let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
          let ips = this.empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));

          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("VERSION"));
          let pdf = new jsPDF();
          documento.getCuentaCobro(pdf, repo, false, 20, tipo);
        } else {
          this.$swal.fire({
            title: "Error al generar el pdf!",
            text: "La cuenta cobro no tiene ninguna factura vinculada.",
            icon: "error"
          });
        }
        this.loadPdf = false
      } catch (error) {
        this.loadPdf = false
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    async excel(id) {
      try {
        this.loadPdfExcel = true;
        let repo = await this.$apiService.index(this.url + "facturas/" + id).then(data => data);
        if (repo.length > 0) {
          let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
          let ips = this.empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("ENTIDAD"));
          //repo.sort((a, b) => a.cifaNumFactura.localeCompare(b.cifaNumFactura));
          repo.sort((a, b) => a.coppId - b.coppId);
          let excel = await import('@/vendor/Export2Excel').then(excel => excel);
          let data = [{sheet:"Page1",data:[]}];
          let head = [{sheet:"Page1",data:['PREFIJO_NUM_FACTURA', 'NUM_FACTURA', 'PACIENTE', 'IDENT.', 'VALOR', 'ESTADO']}];
          for (let fact of repo) {
            data[0].data.push([fact.ccpaPrefijoFactura, fact.ccpaNumFactura, fact.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre1 + ' ' + fact.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre2 + ' ' + fact.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido1 + ' ' + fact.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido2, fact.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, documento.formatNumber(fact.ccpaValor), fact.ccpaEstado]);
          }
          await excel.export_json_to_excel({
            header: head, //Header Required
            data, //Specific data Required
            filename: 'cuentacobro', //Optional
            autoWidth: true, //Optional
            bookType: 'xlsx' //Optional
          });

        } else {
          this.$swal.fire({
            title: "Error al generar el excel!",
            text: "La cuenta cobro no tiene ninguna factura vinculada.",
            icon: "error"
          });
        }
        this.loadPdfExcel = false;
      } catch (error) {
        this.loadPdfExcel = false;
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async pdfFacturas(id) {
      try {
        this.loadPdfFactura = true;
        let repo = null;
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_CON' || this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_SUB') {
          // var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=EVENTO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=EVENTO`).then(data => data);

        } else {
          //var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=CAPITADO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=CAPITADO`).then(data => data);

        }

        if (repo.length > 0) {
          repo.sort((a, b) => a.cifaNumFactura.localeCompare(b.cifaNumFactura));
          let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
          let ips = this.empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("VERSION"));
          let pdf = new jsPDF();

          for (let fact of repo) {
            let factura = await this.$apiService.index("cita/facturaciones/pdf/" + fact.cifaId).then(data => data);
            if (factura) {
              this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${factura.serialId}`).then(data => data);

              let datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + factura.tblCitaCita.citaId).then(data => data);
              let rep = documento.getFactura(pdf, factura, true, datosPaciente, 2, this.datosdian);
              rep.pdf.addPage();
              pdf = rep.pdf;
            }
          }
          if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
          } else {
            window.open(pdf.output('bloburl'), "_blank");
          }

        } else {
          this.$swal.fire({
            title: "Error al generar el pdf!",
            text: "La cuenta cobro no tiene ninguna factura vinculada.",
            icon: "error"
          });
        }
        this.loadPdfFactura = false;
      } catch (error) {
        this.loadPdfFactura = false;
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    async pdfHistorias(id) {
      try {
        this.loadPdfHistoria = true;
        let repo = null;
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_CON' || this.form.tblConfContrato.tipocontrato.comaNombrecorto == 'EVENTO_SUB') {
          // var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=EVENTO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=EVENTO`).then(data => data);

        } else {
          //var repo = await this.$apiService.index(`/reporte/reportefacturas/${anio.comaNombrecorto}/${this.form.ciccTipoMes}/${this.form.cocoId}?tipo=CAPITADO`).then(data => data);
          repo = await this.$apiService.index(`/reporte/reportefacturas/${id}?tipo=CAPITADO`).then(data => data);

        }

        if (repo.length > 0) {
          repo.sort((a, b) => a.cifaNumFactura.localeCompare(b.cifaNumFactura)); //repo.sort((a, b) => new Date(a.cifaFecha).getTime() - new Date(b.cifaFecha).getTime());
          let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
          let ips = this.empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("VERSION"));
          let pdf = new jsPDF();
          let serial = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERIALES").then(data => data);
          serial = serial.filter(e => new Date(e.comaNombrelargo.split(';')[0].replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.comaNombrelargo.split(';')[1].replace(/-+/g, '/')).getTime() >= new Date().getTime());

          serial.sort((a, b) => parseInt(a.comaValor) - parseInt(b.comaValor));
          this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${serial[0].comaId}`).then(data => data);

          for (let fact of repo) {

            let Historia = [];
            let historial = [];
            let secciones = [];
            let diagnosticos = [];
            let ordenes = [];
            let recetarios = [];
            let remisiones = [];
            let pesos = [];
            let vacunas = [];
            let incapacidades = [];
            let certificados = [];
            var primeraOdo = false;
            let datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + fact.tblCitaCita.citaId).then(data => data);
            diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
            ordenes = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/orden?tipo=AUTORIZACION").then(data => data);
            recetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/recetario").then(data => data);
            remisiones = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/remision").then(data => data);
            incapacidades = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=INCAPACIDAD").then(data => data);
            certificados = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=CERTIFICADO").then(data => data);

            Historia = await this.$apiService.index("sistema/historiasfacturaciones/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId).then(data => data);
            if (datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'RUTA PARA LA PRIMERA INFANCIA' || datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre == 'NUTRICIÓN ') {
              let reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=HISTORIAL_PRIMERA_INFANCIA").then(data => data);
              if (reportes) {
                let r = reportes.rows[0];
                r.tblConfReportesparametros.forEach(async o => {
                  o.Valor = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion;
                });
                pesos = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);

              }
            }
            if (['ODONTOLOGÍA GRAL (PRIMERA VEZ)', 'ODONTOLOGÍA GRAL (SEGUIMIENTO)', 'HIGIENE ORAL'].some(e => e == datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre)) {

              var dientesplaca = this.generarDientes('PLACA');

              let yatiene = await this.$apiService.index("cita/odontologicas/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/0").then(data => data);
              if (yatiene.length > 0) {
                yatiene.forEach(diente => {
                  let d = dientesplaca.find(e => e.numero == diente.ciodDiente);
                  if (d) {
                    if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.placa = diente.ciodPlaca;
                    if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.placa = diente.ciodPlaca;
                    if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.placa = diente.ciodPlaca;
                    if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.placa = diente.ciodPlaca;
                    if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.placa = diente.ciodPlaca;
                  }
                });
              }

              var dientesodontograma = this.generarDientes('ODONTOGRAMA');
              var primerOdontograma = this.generarDientes('ODONTOGRAMA');
              let dataprimerOdontograma = this.$apiService.index(`cita/odontologicas/primera/${datosPaciente.coppId}/${datosPaciente.tblCitaFacturaciones[0].cifaId}/1`).then(data => data);

              let yatieneO = await this.$apiService.index("cita/odontologicas/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/1").then(data => data);
              if (yatieneO.length > 0) {
                yatieneO.forEach(diente => {
                  let d = dientesodontograma.find(e => e.numero == diente.ciodDiente);
                  if (d) {
                    if (diente.ciodParte) {
                      if (d.partes.parteTop.id == diente.ciodParte) d.partes.parteTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteBottom.id == diente.ciodParte) d.partes.parteBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.convencion = diente.ciodConvencion;
                    } else {
                      d.convencion = diente.ciodConvencion;
                    }
                  }
                });
              }
              if (dataprimerOdontograma.length > 0) {

                primeraOdo = true;
                dataprimerOdontograma.forEach(diente => {
                  let d = primerOdontograma.find(e => e.numero == diente.ciodDiente);
                  if (d) {
                    if (diente.ciodParte) {
                      if (d.partes.parteTop.id == diente.ciodParte) d.partes.parteTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteBottom.id == diente.ciodParte) d.partes.parteBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.convencion = diente.ciodConvencion;
                    } else {
                      d.convencion = diente.ciodConvencion;
                    }
                  }
                });

              }
            }
            let reportes = await this.$apiService.index("reporte/reportes/index?offset=0&coreNombre=VACUNAS_PACIENTE").then(data => data);
            if (reportes) {
              let r = reportes.rows[0];
              r.tblConfReportesparametros.forEach(async o => {
                o.Valor = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion;
              });
              vacunas = await this.$apiService.create("reporte/reportes/ejecutar/", r).then(data => data);

            }
            if (Historia.length > 0) {
              historial = await this.$apiService.index(`sistema/historiasfacturaciones/historial/${datosPaciente.coppId}/${datosPaciente.tblCitaFacturaciones[0].cifaFecha}?cocsId=${datosPaciente.cocsId}`).then(data => data);
              Historia.forEach((historia) => {
                if (secciones.find(e => e.hiseId == historia.tblConfHistoriaseccione.seccion.hiseId) != undefined) {
                  secciones.find(e => e.hiseId == historia.tblConfHistoriaseccione.seccion.hiseId).campos.push(historia);
                } else {
                  historia.tblConfHistoriaseccione.seccion.campos = [];
                  historia.tblConfHistoriaseccione.seccion.campos.push(historia);
                  secciones.push(historia.tblConfHistoriaseccione.seccion);
                }
              });
              secciones.sort((a, b) => parseInt(a.hiseOrden) - parseInt(b.hiseOrden));
              secciones.forEach((seccion) => {
                seccion.campos.sort((a, b) => parseInt(a.tblConfHistoriaseccione.hiseOrden) - parseInt(b.tblConfHistoriaseccione.hiseOrden));
              });
              let rep = await documento.getHistoria(pdf, datosPaciente, Historia, secciones, ordenes, diagnosticos, remisiones, recetarios, historial, dientesplaca, dientesodontograma, pesos, incapacidades, certificados, vacunas, (primeraOdo) ? primerOdontograma : [], true);
              pdf = rep.pdf;

            }

          }
          pdf.setFontSize(7);
          var pageCount = pdf.internal.getNumberOfPages(); // pageCount es el nº de páginas
          for (let i = 0; i < pageCount; i++) { // código que se repite para cada página (bucle)
            pdf.setPage(i);
            pdf.text(190, 285, "Página " + pdf.internal.getCurrentPageInfo().pageNumber + " de " + pageCount);
            pdf.setFontSize(7);
            pdf.setFontStyle("normal");
            pdf.text('IPS Tools (HIS - Hospital Information System)', 14, 279 + 8);
            pdf.text('TEL: 3168775054', 14, 279 + 11);
            pdf.text('® 2020 Powered by www.jasoftwaresas.com', 14, 279 + 14);
          }
          if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
          } else {
            window.open(pdf.output('bloburl'), "_blank");
          }

        } else {
          this.$swal.fire({
            title: "Error al generar el pdf!",
            text: "La cuenta cobro no tiene ninguna factura vinculada.",
            icon: "error"
          });
        }
        this.loadPdfHistoria = false;
      } catch (error) {
        this.loadPdfHistoria = false;
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    generarDientesplaca(t, l, ini, fin, valor, tipo, tipo2) {
      let dientes = [];
      let left = 0;
      for (let i = ini; i < fin; i++) {
        let parteTop = {
          numero: valor,
          convencion: null,
          id: "parte6-diente-" + valor,
          name: "parte6",
          backgroundColor: "#FFFFFF",
          top: t + 63,
          left: l + 53,

        };
        let parteBottom = {
          numero: valor,
          convencion: null,
          id: "parte7-diente-" + valor,
          name: "parte7",
          backgroundColor: "#FFFFFF",
          top: t + 113,
          left: l + 53
        };
        let parteLeftTop = {
          numero: valor,
          placa: 0,
          convencion: null,
          id: "parte1-diente-" + valor,
          name: "parte1",
          backgroundColor: "#FFFFFF",
          top: t + 88,
          left: l + 38
        };
        let parteRightTop = {
          numero: valor,
          placa: 0,
          convencion: null,
          id: "parte2-diente-" + valor,
          name: "parte2",
          backgroundColor: "#FFFFFF",
          top: t + 73,
          left: l + 53
        };
        let parteLeftBottom = {
          numero: valor,
          placa: 0,
          convencion: null,
          id: "parte3-diente-" + valor,
          name: "parte3",
          backgroundColor: "#FFFFFF",
          top: t + 103,
          left: l + 53
        };
        let parteRightBottom = {
          numero: valor,
          placa: 0,
          convencion: null,
          id: "parte4-diente-" + valor,
          name: "parte4",
          backgroundColor: "#FFFFFF",
          top: t + 88,
          left: l + 68
        };
        let parteMedio = {
          numero: valor,
          placa: 0,
          convencion: null,
          id: "parte5-diente-" + valor,
          name: "parte5",
          backgroundColor: "#FFFFFF",
          top: t + 88,
          left: l + 53
        };

        if (i > ini) {
          left = 70;

          parteTop.left = parteTop.left + left * (i - ini);
          parteBottom.left = parteBottom.left + left * (i - ini);
          parteLeftTop.left = parteLeftTop.left + left * (i - ini);
          parteRightTop.left = parteRightTop.left + left * (i - ini);
          parteLeftBottom.left = parteLeftBottom.left + left * (i - ini);
          parteRightBottom.left = parteRightBottom.left + left * (i - ini);
          parteMedio.left = parteMedio.left + left * (i - ini);
        }

        dientes.push({
          id: "diente-" + valor,
          convencion: null,
          numero: valor,
          partes: (tipo2 == 'PLACA') ? {
            //parteTop: parteTop,
            //parteBottom: parteBottom,
            parteLeftTop: parteLeftTop,
            parteRightTop: parteRightTop,
            parteLeftBottom: parteLeftBottom,
            parteRightBottom: parteRightBottom,
            parteMedio: parteMedio
          } : {
            parteTop: parteTop,
            parteBottom: parteBottom,
            parteLeftTop: parteLeftTop,
            parteRightTop: parteRightTop,
            parteLeftBottom: parteLeftBottom,
            parteRightBottom: parteRightBottom,
            parteMedio: parteMedio
          }
        });
        if (tipo == 'negativo') {
          valor = valor - 1;
        } else {
          valor = valor + 1;
        }

      }
      return dientes;
    },
    generarDientes(tipo) {
      let dientes = [];
      dientes = dientes.concat(this.generarDientesplaca(0, 0, 0, 8, 18, 'negativo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(0, 590, 8, 16, 21, 'positivo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(100, 210, 16, 21, 55, 'negativo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(100, 590, 21, 26, 61, 'positivo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(200, 210, 26, 31, 85, 'negativo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(200, 590, 31, 36, 71, 'positivo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(300, 0, 36, 44, 48, 'negativo', tipo));
      dientes = dientes.concat(this.generarDientesplaca(300, 590, 44, 52, 31, 'positivo', tipo));
      return dientes;
    },

    async limpiar() {
      this.form = {
        ciccId: null,

        ciccFecha: new Date().yyyymmdd(),
        ciccNumAfiliado: null,
        ciccValorAfiliado: null,
        ciccNumFactura: 0,
        ciccTipoMes: null,
        ciccTipoAnio: null,
        cocoId: null,
        ciccTipoEstadocuenta: null,

      };
      /* let serial = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERIALES").then(data => data);
            serial = serial.filter(e => new Date(e.comaNombrelargo.split(';')[0].replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.comaNombrelargo.split(';')[1].replace(/-+/g, '/')).getTime() >= new Date().getTime());

            serial.sort((a, b) => parseInt(a.comaValor) - parseInt(b.comaValor));
            this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${serial[0].comaId}`).then(data => data);
            this.form.ciccNumFactura = this.datosdian.find(e => e.comaNombrecorto == 'PREFIJO_SERIE').comaValor + (parseInt(this.datosdian.find(e => e.comaNombrecorto == 'NUMERO_ACTUAL').comaValor) + 1);
        */
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>

<style>

</style>
