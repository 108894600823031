<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="12" v-if="form">
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" class="text-center">
                        <span class="headline green--text"> CUENTA DE COBRO NUMERO {{form.ciccNumFactura}}</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <span class="headline green--text">DATOS DE LA CUENTA DE COBRO</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">FECHA INICIAL: </label>
                        {{form.ciccFechainicial}}
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">FECHA FINAL: </label>
                        {{form.ciccFechafinal}}
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">TIPO: </label>
                        {{form.tipopaquete.comaNombrelargo}}
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">CONTRATO: </label>
                        {{form.tblConfContrato.cocoNombre}}
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">ESTADO: </label>
                        {{form.estado.comaNombrecorto}}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
          
            <v-col md="12">
              <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1">
                    Servicios prestados
                </v-tab>
                <v-tab href="#tab-2">
                    Facturas
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1" >
                  <v-data-table v-model="seleccionados" :headers="headers" show-select dense item-key="ccpaId" :items="lista" sort-by="coppId" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar>
                            <v-toolbar-title class="primary--text">Servicios prestados</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn class="mx-2" dark small color="primary" @click="cambiarEstados('Validado')">
                                Validar <v-icon dark>mdi-archive-edit</v-icon>
                            </v-btn>
                            <v-btn class="mx-2" dark small color="primary" @click="cambiarEstados('Sin validar')">
                                Desvalidar <v-icon dark>mdi-archive-edit</v-icon>
                            </v-btn>
                            <v-btn class="mx-2" dark small color="primary" @click="generarNumFacturas()" :loading="loadingFactura">
                                Generar numeros de facturas <v-icon dark>mdi-archive-edit</v-icon>
                            </v-btn>
                            <v-btn class="mx-2" dark small color="primary" @click="dialog=true">
                                Trasladar <v-icon dark>mdi-swap-horizontal</v-icon>
                            </v-btn>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                        <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                    </template>
                    <!-- <template v-slot:[`item.data-table-select`]="{ on, props }">
                        <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
                    </template> -->
                    <template v-slot:[`item.coppId`]="{ item }">
                        {{item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre1 + ' ' +item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre2 + ' ' + item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido1 + ' ' + item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido2 + ' - '+item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn v-if="item.ccpaEstado == 'Sin validar'" fab x-small icon color="success" @click="validar(item.ccpaId)">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:no-data>
                        <label class="primary--text">No hay datos disponibles</label>
                    </template>
                </v-data-table>
                </v-tab-item>
                <v-tab-item value="tab-2" >
                  <Facturaselectronicas></Facturaselectronicas>
                </v-tab-item>
            </v-tabs-items>
                
            </v-col>
        </v-row>
         <v-form @submit.prevent="trasladar">
            <v-dialog v-model="dialog" width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Trasladar facturas</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined  required v-model="newCuenta" :items="cuentas"  item-text="ciccId" item-value="ciccId" item-color="primary" label="Cuenta" clearable>
                                            <template v-slot:selection="data">
                                                {{`Creado el ${data.item.ciccFecha} - ${data.item.tblConfContrato.cocoNombre} - del ${ data.item.ciccFechainicial} al ${data.item.ciccFechafinal}`}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="`Creado el ${data.item.ciccFecha} - ${data.item.tblConfContrato.cocoNombre} - del ${ data.item.ciccFechainicial} al ${data.item.ciccFechafinal}`"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.tipopaquete.comaNombrelargo">
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="dialog = false">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="trasladar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>

<script>
import Facturaselectronicas from './Facturaselectronicas.vue';
export default {
  components:{Facturaselectronicas},
  data() {
    return {
      dialog: false,
      isNew: true,
      url: "cita/cuentascobros/",
      token: this.$cookies.get("token"),
      form: null,
      seleccionados: [],
      tab:"tab-1",
      loadingFactura:false,
      filtros: [{
          text: "Factura",
          value: null,
          tipo: "text",
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Estado",
          value: null,
          tipo: "text",
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
      ],
      headers: [{
          text: "Prefijo de la factura",
          value: "ccpaPrefijoFactura"
        },
        {
          text: "Numero de la factura",
          value: "ccpaNumFactura"
        },
        {
          text: "Numero de autorizacion",
          value: "ccpaAutorizacion"
        },
        {
          text: "Tipo",
          value: "ccpaTipo"
        },
        {
          text: "Sub Tipo",
          value: "ccpaSubTipo"
        },
        {
          text: "Paciente",
          value: "coppId"
        },
        {
          text: "Valor",
          value: "ccpaValor"
        },
        {
          text: "Estado",
          value: "ccpaEstado"
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false
        }
      ],
      lista: [],
      listaCopia: [],
      cuentas:[],
      newCuenta:null
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    if (this.$route.params.id) {
      this.form = await this.$apiService.index(this.url + "view/" + this.$route.params.id).then(data => data);
      this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturasitems/" + this.$route.params.id).then(data => data);
      this.cuentas = await this.$apiService.index(this.url + "index").then(data => data);
      this.cuentas = this.cuentas.filter(e => e.ciccId != this.$route.params.id);
      this.cuentas.sort((a,b)=> b.ciccId - a.ciccId );
    }
    this.$store.commit('setCargaDatos', false);
  },
  methods: {
    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.ccpaNumFactura != null && e.ccpaNumFactura.toString().indexOf(this.filtros[0].value) != -1
        );
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.ccpaEstado.indexOf(this.filtros[1].value) != -1
        );
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(e => e.coppId == this.filtros[2].value);
      }

    },
    async validar(id) {
      let data = null;
      this.loadingFactura = true;
      try {
        data = await this.$apiService.update(this.url + "validar/" + id, {
          ccpaEstado: 'Validado'
        }).then(data => data);
        if (data) {
          this.$store.commit('setCargaDatos', true);
          this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturasitems/" + this.$route.params.id).then(data => data);
          this.$store.commit('setCargaDatos', false);
          this.$swal.fire({
            title: "Completado!",
            text: "Validado correctamente",
            icon: "success",
            confirmButtonText: "Ok"
          });
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador:" + data.message,
          icon: "error"
        });
      }
      this.loadingFactura = false;
    },
    async cambiarEstados(estado) {
      let data = null;
      this.loadingFactura = true;
      try {
        data = await this.$apiService.update(this.url + "validar/" + 0, {
          seleccionados:this.seleccionados,
          ccpaEstado: estado
        }).then(data => data);
        if (data) {
          this.$store.commit('setCargaDatos', true);
          this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturasitems/" + this.$route.params.id).then(data => data);
          this.$store.commit('setCargaDatos', false);
          this.$swal.fire({
            title: "Completado!",
            text: "Validado correctamente",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.seleccionados = [];
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador:" + data.message,
          icon: "error"
        });
      }
      this.loadingFactura = false;
    },
    async trasladar(){
       let data = null;
       this.loadingFactura = true;
      try {
        data = await this.$apiService.create(`${this.url}trasladar/${this.$route.params.id}/${this.newCuenta}`, this.seleccionados).then(data => data);
        if (data) {
          this.$store.commit('setCargaDatos', true);
          this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturasitems/" + this.$route.params.id).then(data => data);
          this.$store.commit('setCargaDatos', false);
          this.$swal.fire({
            title: "Completado!",
            text: "Trasladados correctamente",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.seleccionados = [];
          this.dialog = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador:" + data.message,
          icon: "error"
        });
      }
      this.newCuenta = null
      this.loadingFactura = false;
    },
    async generarNumFacturas() {
      let data = null;
      this.loadingFactura = true;
      try {
        data = await this.$apiService.index(this.url + "generarNumFacturas/" + this.$route.params.id).then(data => data);
        if (data) {
          this.$store.commit('setCargaDatos', true);
          this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturasitems/" + this.$route.params.id).then(data => data);
          this.$store.commit('setCargaDatos', false);
          this.$swal.fire({
            title: "Completado!",
            text: "Generados correctamente",
            icon: "success",
            confirmButtonText: "Ok"
          });
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador:" + error.response.data.message,
          icon: "error"
        });
      }
      this.loadingFactura = false;
    }
  }
};

</script>

<style>

</style>
