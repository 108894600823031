<template>
 <v-container>
     <v-row>
         <v-col md="12">
             <v-data-table v-model="seleccionados" :headers="headers" show-select dense item-key="ccpaId" :items="lista" sort-by="coppId" class="elevation-1">
                 <template v-slot:top>
                     <v-toolbar>
                         <v-toolbar-title class="primary--text">Facturas</v-toolbar-title>
                         <v-divider class="mx-4" inset vertical></v-divider>
                         <v-btn class="mx-2" dark small color="primary" @click="dialog=true">
                             Trasladar <v-icon dark>mdi-swap-horizontal</v-icon>
                         </v-btn>
                         <div class="flex-grow-1"></div>
                     </v-toolbar>
                     <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                 </template>
                 <template v-slot:no-data>
                     <label class="primary--text">No hay datos disponibles</label>
                 </template>
             </v-data-table>
         </v-col>
     </v-row>
      <v-form @submit.prevent="trasladar">
         <v-dialog v-model="dialog" width="800px" max-width="800px">
             <v-card>
                 <v-card-title>
                     <span class="headline">Trasladar facturas</span>
                 </v-card-title>
                 <v-divider></v-divider>
                 <v-card-text>
                     <v-container>
                         <v-row>
                             <v-col cols="12" sm="12" md="12">
                                 <v-autocomplete outlined  required v-model="newCuenta" :items="cuentas"  item-text="ciccId" item-value="ciccId" item-color="primary" label="Cuenta" clearable>
                                         <template v-slot:selection="data">
                                             {{`Creado el ${data.item.ciccFecha} - ${data.item.tblConfContrato.cocoNombre} - del ${ data.item.ciccFechainicial} al ${data.item.ciccFechafinal}`}}
                                         </template>
                                         <template v-slot:item="data">
                                             <template v-if="(typeof data.item !== 'object')">
                                                 <v-list-item-content v-text="data.item"></v-list-item-content>
                                             </template>
                                             <template v-else>
                                                 <v-list-item-content>
                                                     <v-list-item-title v-html="`Creado el ${data.item.ciccFecha} - ${data.item.tblConfContrato.cocoNombre} - del ${ data.item.ciccFechainicial} al ${data.item.ciccFechafinal}`"></v-list-item-title>
                                                     <v-list-item-subtitle v-html="data.item.tipopaquete.comaNombrelargo">
                                                     </v-list-item-subtitle>
                                                 </v-list-item-content>
                                             </template>
                                         </template>
                                     </v-autocomplete>
                             </v-col>
                         </v-row>
                     </v-container>
                 </v-card-text>
                 <v-divider></v-divider>
                 <v-card-actions>
                     <div class="flex-grow-1"></div>
                     <v-btn color="primary darken-1" text @click="dialog = false">Cancelar</v-btn>
                     <v-btn color="primary darken-1" dark @click="trasladar">Guardar</v-btn>
                 </v-card-actions>
             </v-card>
         </v-dialog>
     </v-form>
 </v-container>
</template>

<script>
export default {
data() {
 return {
   dialog: false,
   isNew: true,
   url: "cita/cuentascobros/",
   token: this.$cookies.get("token"),
   form: null,
   seleccionados: [],
   filtros: [{
       text: "Factura",
       value: null,
       tipo: "text",
       /*si es tipo lista descomentar esta parte*/
       /* lista: [],
       listatext: "nombre del campo que se mostrara",
       listavalue: "nombre del valor que se mandara"*/
     }
   ],
   headers: [
     {
       text: "Numero de la factura",
       value: "ciflNumFactura"
     },
     {
       text: "Fecha de la factura",
       value: "ciflFecha"
     },
     {
       text: "Valor",
       value: "ciflValor"
     },
     {
       text: "Acciones",
       value: "action",
       sortable: false
     }
   ],
   lista: [],
   listaCopia: [],
   cuentas:[],
   newCuenta:null
 };
},

computed: {
 formTitle() {
   return this.isNew === true ? "Nuevo" : "Actualizar";
 }
},
watch: {
 dialog(val) {
   val || this.close();
 }
},
created: async function() {
 this.$store.commit('setCargaDatos', true);
 if (this.$route.params.id) {
   this.listaCopia = this.lista = await this.$apiService.index(this.url + "facturas/" + this.$route.params.id).then(data => data);
   this.cuentas = await this.$apiService.index(this.url + "index").then(data => data);
   this.cuentas = this.cuentas.filter(e => e.ciccId != this.$route.params.id);
   this.cuentas.sort((a,b)=> b.ciccId - a.ciccId );
 }
 this.$store.commit('setCargaDatos', false);
},
methods: {
 buscar(filtros) {
   this.filtros = filtros;
   this.lista = this.listaCopia;
   if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
     this.lista = this.lista.filter(
       e => e.ciflNumFactura != null &&  e.ciflNumFactura.toString().indexOf(this.filtros[0].value) != -1
     );
   }

 }
}
};

</script>

<style>

</style>
